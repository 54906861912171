import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import "react-input-range/lib/css/index.css";
import "./EntryScreenCatelog.css";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

export default class EntryScreenCatelog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    const { width } = this.state;
    const { redeemptionData } = this.props;
    return (
      <div className="image" id="entry-screen-catalog">
        <Row>
          {width > 768 && (
            <Col
              sm="12"
              md="4"
              className="text-align-center"
              style={{ paddingRight: "0px", paddingLeft: "50px" }}
            >
              <img className="logo" src="images/logo-small.png" alt='' />
              <span>
                <div className="title">
                  <div>ADVANTAGE REDEMPTION</div>
                  <div className="margin-top-10">
                    CATALOGUE{" "}JUNE 2024
                    {/* {redeemptionData.lower_month && (
                      <span className="text-uppercase">
                        {redeemptionData.lower_month} {" "}
                        {redeemptionData.upper_month}
                      </span>
                    )} */}
                  </div>
                </div>

                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-save-button redemption-button"
                  color="warning"
                  onClick={this.props.showGiftCat()}
                >
                  <div>Welcome to</div>
                  <div>JK Tyre Advantage Rewards</div>
                </Button>

                {/** Uncomment the div when need to show the terms and conditions */}
                <div style={{ marginTop: "10px" }}>
                  <a
                    href="https://images.jktyrecrm.in/terms-condition/tnc-advantage-redemption-catalog.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                    onClick={() => {
                      GoogleAnalticsService.event(
                        "Redemption Catalogue",
                        "Redemption Catalogue Terms And Condition Click"
                      );
                    }}
                  >
                    Terms & Condition
                  </a>
                </div>
              </span>
            </Col>
          )}
          <Col
            sm="12"
            md="8"
            className="text-align-center"
            style={{ paddingBottom: "40px" }}
          >
            <img src="images/illustration.png" class="Illustration" alt='' />
          </Col>
          {width <= 768 && (
            <Col
              sm="12"
              md="4"
              className="text-align-center"
              style={{ paddingRight: "0px", marginBottom: "20px" }}
            >
              <img className="logo" src="images/logo-small.png" alt='' />
              <span>
                <div className="title">
                  <div>ADVANTAGE REDEMPTION</div>
                  <div className="margin-top-10">
                    CATALOGUE{" "}JUNE 2024
                    {/* {redeemptionData.lower_month && (
                      <span className="text-uppercase">
                        {redeemptionData.lower_month} {" "}
                        {redeemptionData.upper_month}
                      </span>
                    )} */}
                  </div>
                </div>

                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-save-button redemption-button"
                  color="warning"
                  onClick={this.props.showGiftCat()}
                >
                  <div>Welcome to</div>
                  <div>JK Tyre Advantage Rewards</div>
                </Button>
                <div style={{ marginTop: "10px" }}>
                  <a
                    href="https://images.jktyrecrm.in/terms-condition/tnc-advantage-redemption-catalog.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                    onClick={() => {
                      GoogleAnalticsService.event(
                        "Redemption Catalogue",
                        "Redemption Catalogue Terms And Condition Click"
                      );
                    }}
                  >
                    Terms & Condition
                  </a>
                </div>
              </span>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
