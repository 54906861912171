import About from "../pages/About/About";
import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import Profile from "../pages/Profile/Profile";
import OnBoardingPage from "../pages/OnboardingPage/OnBoardingPage";
import WarrantyRegistration from "../pages/WarrantyRegistration/WarrantyRegistration";
import PrimaryPointsReport from "../pages/PrimaryPointsReport/PrimaryPointsReport";
import SecondaryPointsReport from "../pages/SecondaryPointsReport/SecondaryPointsReport";
import PrimaryPointAllDealerReport from "../pages/PrimaryPointAllDealerReport/PrimaryPointAllDealerReport";
import SecondaryPointAllDealerReport from "../pages/SecondaryPointAllDealerReport/SecondaryPointAllDealerReport";
import WhatsNew from "../pages/WhatsNew/WhatsNew";
import SetNotification from "../pages/SetNotification/SetNotification";
import Dashboard from "../pages/Dashboard/Dashboard";
import DashboardEmployee from "../pages/DashboardEmployee/DashboardEmployee";
import ContactUs from "../pages/ContactUs/ContactUs";
import SelectVehicle from "../pages/WarrantyReplacement/SelectVehicle";
import WarrantyCheck from "../pages/WarrantyReplacement/WarrantyCheck";
import ProposalDashboard from "../pages/ProposalDashboard/ProposalDashboard";
import ProposalDetails from "../pages/ProposalDetails/ProposalDetails";
import ProposalList from "../pages/ProposalList/ProposalList";
import ProjectStageStatus from "../pages/ProjectStageStatus/ProjectStageStatus";
import ProposalStages from "../pages/ProposalStages/ProposalStages";
import ProjectsStageAM from "../pages/ProjectsStageAM/ProjectsStageAM";
import ProposalAssign from "../pages/ProposalAssign/ProposalAssign";
import MerchandiseWallet from "../pages/merchandise-wallet/MerchandiseWallet";
import ProjectStagePtp from "../pages/ProjectStagePtp/ProjectStagePtp";
import SalesDataUpload from "../pages/SalesDataUpload/SalesDataUpload";
import MigrateData from "../pages/MigrateData/MigrateData";
import SubDealerOrBranch from "../pages/SubDealerOrBranch/SubDealerOrBranch";
import Campaign from "../pages/Campaign/Campaign";
import CustomerManagement from "../pages/CustomerManagement/CustomerManagement";
import ManageGroupPage from "../pages/ManageGroupPage/ManageGroupPage";
import HeadOfficeDashboard from "../pages/HeadOfficeDashboard/HeadOfficeDashboard";
import MerchandiseOrders from "../pages/MerchandiseOrders/MerchandiseOrders";
import PerformaInvoice from "../components/MerchandiseReport/PerformaInvoice";
import MigrateQuarterlyPoints from "../pages/MigrateQuarterlyPoints/MigrateQuarterlyPoints";
import MigrateYearlyPoints from "../pages/MigrateYearlyPoints/MigrateYearlyPoints";
import SpecialEngagement from "../pages/SpecialEngagement/SpecialEngagement";
import CustomerIntraction from "../pages/CustomerIntraction/CustomerIntraction";
import PcrSchemeDealer from "../pages/PcrSchemeDealer/PcrSchemeDealer";
import TradeSchemeReport from "../pages/TradeSchemeReport/TradeSchemeReport";
import TradeSchemeDealerReport from "../pages/TradeSchemeDealerReport/TradeSchemeDealerReport";
import GiftCatalog from "../pages/GiftCatalog/GiftCatalog";
import GiftItemDetail from "../pages/GiftItem/GiftItemDetail";
import GiftItemList from "../pages/GiftItemList/GiftItemList";
import CustomTimePicker from "../components/CustomTimePicker/CustomTimePicker";
import exceptionalPointsUpload from "../pages/exceptionalPointsUpload/exceptionalPointsUpload";
import NotificationPreference from "../pages/NotificationPreference/NotificationPreference";
import paymentSuccess from "../pages/paymentSuccess/paymentSuccess";
import OrderHistory from "../pages/OrderHistory/OrderHistory";
import BlockCustomer from "../pages/BlockCustomer/BlockCustomer";
import EmailPreference from "../pages/EmailPreference/EmailPreference";
import OrderHistoryHo from "../pages/OrderHistoryHo/OrderHistoryHo";
import MarketingCoupon from "../pages/MarketingCoupon/MarketingCoupon";
import redeemCouponReport from "../pages/RedeemCouponReport/RedeemCouponReport";
import CreateBanner from "../pages/CreateBanner/CreateBanner";
import BannerList from "../pages/BannerList/BannerList";
import DealerDashboard from "../pages/DealerDashboard/DealerDashboard";
import CustomerReference from "../pages/CustomerReference/CustomerReference";
import CustomerRating from "../pages/CustomerRating/CustomerRating";
import GoogleBusiness from "../pages/GoogleBusiness/GoogleBusiness";
import UserUpdates from "../pages/UserUpdates/UserUpdates";
import NotificationRedirect from "../pages/NotificationRedirect/NotificationRedirect";
import SMSReport from "../pages/SMSReport/SMSReport";
import Poster from "../pages/Poster/Poster";
import PosterList from "../pages/PosterList/PosterList";
import CreatePoster from "../pages/CreatePoster/CreatePoster";
import CipRegitration from "../pages/CipRegitration/CipRegitration";
import CipDetail from "../pages/CipDetail/CipDetail";
import CipSummary from "../pages/CipSummary/CipSummary";
import PrimaryPointsReportDealer from "../pages/PrimaryPointsReportDealer/PrimaryPointsReportDealer";
import RetailMarketingSystem from "../pages/RetailMarketingSystem/RetailMarketingSystem";
import RetailMarketingRecce from "../pages/RetailMarketingSystem/RetailMarketingRecce/RetailMarketingRecce";
import RetailMarketingRecceListHO from "../pages/RetailMarketingSystem/RetailMarketingRecceListHO/RetailMarketingRecceListHO";
import RecceProgressStatus from "../pages/RetailMarketingSystem/RecceProgressStatus/RecceProgressStatus";
import EventRegistration from "../pages/EventRegistration/EventRegistration";

/** Adding RedemptionCatalog V2 */
// import RedemptionCatalogV2 from "../pages/RedemptionCatalogV2/RedemptionCatalogV2";
import BrandStoreLocation from "../pages/BrandStoreLocation/BrandStoreLocation";
/** Adding Ranger Event */
import RangerEvent from "../pages/RangerEvent";
import RetailMarketingHOSystem from "../pages/RetailMarketingSystem/RetailMarketingHOSystem";
import GiftCatalogV2 from "../pages/GiftCatalogV2/GiftCatalog";
import Analysis from "../pages/Analysis/Analysis";
import GenerativeAI from "../pages/GenerativeAI/GenerativeAI";
// import GiftCatalogV2 from "../pages/GiftCatalogV2/GiftCatalog";
import { Permissions } from "../constants/constant.permissions";
import { Roles } from "../constants/constant.role";

export const publicRouteObj = [
  {
    exact: true,
    path: "/login",
    component: Login,
    key: "Login",
    sidenav: false,
    header: false,
  },
  {
    exact: true,
    path: "/forgot-password",
    component: ForgotPassword,
    key: "ForgotPassword",
    sidenav: false,
    header: false,
  },
  {
    exact: true,
    path: "/change-password",
    component: ChangePassword,
    key: "ChangePassword",
    sidenav: false,
    header: false,
  },

  // {
  //   exact: true,
  //   path: '/primary-points-report',
  //   component: PrimaryPointsReport,
  //   key: 'PrimaryPointsReport',
  //   sidenav: true,
  //   header: true
  // },
];

export const privateRouteObj = [
  {
    exact: true,
    path: "/event-registration",
    component: EventRegistration,
    key: "Event",
    isPrivateAndPublic: true,
    sidenav: false,
    header: false,
  },
  {
    exact: true,
    path: "/ranger-event",
    component: RangerEvent,
    isPrivateAndPublic: true,
    key: "RangerEvent",
    sidenav: false,
    header: false,
  },
  {
    exact: true,
    path: "/print-performa-invoice",
    component: PerformaInvoice,
    key: "PerformaInvoice",
    sidenav: false,
    header: false,
    footer: false,
    permission: [Permissions.PRINT_PERFOMA_INVOICE],
    role: [Roles.SUPPORT_ROLE],
  },
  {
    exact: true,
    path: "/merchandise-orders",
    component: MerchandiseOrders,
    key: "MerchandiseOrders",
    sidenav: true,
    header: true,
    permission: [Permissions.MERCHANDISE_ORDER],
    role: [Roles.SUPPORT_ROLE],
  },
  {
    exact: true,
    path: "/about",
    component: About,
    key: "About",
    sidenav: true,
    header: true,
    isPrivateAndPublic: true,
    permission: [Permissions.ABOUT_PROGRAMME],
    role: [
      Roles.DEALER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.ZTM_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    exact: true,
    path: "/special-engagement",
    component: SpecialEngagement,
    key: "SpecialEngagement",
    sidenav: true,
    header: true,
    isPrivateAndPublic: true,
    permission: [Permissions.SPECIAL_ENGAGEMENT],
    role: [
      Roles.DEALER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.ZTM_ROLE,
      Roles.HEAD_OFFICE_ROLE,
    ],
  },
  {
    exact: true,
    path: "/manage-group",
    component: ManageGroupPage,
    key: "ManageGroupPage",
    sidenav: true,
    header: true,
    permission: [Permissions.MANAGE_GROUP],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/warranty-registration",
    component: WarrantyRegistration,
    key: "WarrantyRegistration",
    sidenav: true,
    header: true,
    permission: [Permissions.WARRANTY_REGISTRATION],
    role: [Roles.DEALER_ROLE, Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE],
  },
  {
    exact: true,
    path: "/warranty-replacement",
    component: SelectVehicle,
    key: "WarrantyReplacement",
    sidenav: true,
    header: true,
    permission: [Permissions.CHECK_ELIGIBILITY_FOR_WARRANTY],
    role: [Roles.DEALER_ROLE, Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE],
  },
  {
    exact: true,
    path: "/warranty-check",
    component: WarrantyCheck,
    key: "WarrantyCheck",
    sidenav: true,
    header: true,
    permission: [Permissions.WARRANTY_CHECK],
    role: [Roles.DEALER_ROLE, Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE],
  },
  {
    exact: true,
    path: "/",
    component: Dashboard,
    key: "Dashboard",
    sidenav: true,
    header: true,
    footer: true,
    permission: [Permissions.POINTS_DASHBOARD],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/add-customer",
    component: Dashboard,
    key: "Dashboard",
    sidenav: true,
    header: true,
    footer: true,
    permission: [Permissions.ADD_CUSTOMER],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/point-dashboard",
    component: DashboardEmployee,
    key: "DashboardEmployee",
    sidenav: true,
    header: true,
    footer: true,
    permission: [Permissions.POINTS_DASHBOARD],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.ZTM_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    exact: true,
    path: "/profile",
    component: Profile,
    key: "Profile",
    sidenav: true,
    header: true,
    permission: [Permissions.PROFILE],
    role: [Roles.DEALER_ROLE, Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE],
  },
  {
    exact: true,
    path: "/primary-points-report",
    component: PrimaryPointsReport,
    key: "PrimaryPointsReport",
    sidenav: true,
    header: true,
    permission: [Permissions.POINT_EARN_REPORT],
    role: [
      Roles.DEALER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    exact: true,
    path: "/warranty-score-card",
    component: SecondaryPointsReport,
    key: "SecondaryPointsReport",
    sidenav: true,
    header: true,
    permission: [Permissions.WARRANTY_REPORT],
    role: [
      Roles.DEALER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
      Roles.SUB_DEALER_ROLE,
      Roles.BRANCH_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    exact: true,
    path: "/offtake-score-card-report",
    component: PrimaryPointAllDealerReport,
    key: "PrimaryPointAllDealerReport",
    sidenav: true,
    header: true,
    permission: [Permissions.OFFTAKE_REPORT],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/warranty-score-card-report",
    component: SecondaryPointAllDealerReport,
    key: "SecondaryPointAllDealerReport",
    sidenav: true,
    header: true,
    permission: [Permissions.WARRANTY_REPORT_EMPLOYEE],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    exact: true,
    path: "/whats-new",
    component: WhatsNew,
    key: "whatsNew",
    sidenav: true,
    header: true,
    permission: [Permissions.WHATS_NEW],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/marketing-coupon",
    component: MarketingCoupon,
    key: "marketingCoupon",
    sidenav: true,
    header: true,
    footer: true,
    permission: [Permissions.MARKETING_COUPON_REPORT],
    role: [Roles.HEAD_OFFICE_ROLE],
  },
  {
    exact: true,
    path: "/redeem-coupon-report",
    component: redeemCouponReport,
    key: "redeemCouponReport",
    sidenav: true,
    header: true,
    footer: true,
    permission: [Permissions.REDEEM_MARKETING_COUPON],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/download_poster",
    component: Poster,
    key: "downloadPoster",
    sidenav: true,
    header: true,
    footer: true,
    permission: [Permissions.DOWNLOAD_POSTER],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/contact-us",
    component: ContactUs,
    key: "ContactUs",
    sidenav: true,
    header: true,
    isPrivateAndPublic: true,
    permission: [Permissions.CONTACT_US],
    role: [
      Roles.DEALER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.ZTM_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.SUB_DEALER_ROLE,
      Roles.BRANCH_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    exact: true,
    path: "/proposal-dashboard",
    component: ProposalDashboard,
    key: "ProposalDashboard",
    sidenav: true,
    header: true,
    permission: [Permissions.PROPOSAL_DASHBOARD],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/retail-marketing-recce-list",
    component: RetailMarketingRecceListHO,
    key: "",
    sidenav: true,
    header: true,
    permission: [Permissions.PROPOSAL_DASHBOARD],
    role: [Roles.HEAD_OFFICE_ROLE],
  },
  {
    exact: true,
    path: "/proposal-details",
    component: ProposalDetails,
    key: "proposalDetails",
    sidenav: true,
    header: true,
    permission: [Permissions.PROPOSAL_DETAIL],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/projects-stage",
    component: ProposalList,
    key: "projectStage",
    sidenav: true,
    header: true,
    permission: [Permissions.PROJECT_STAGE],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/proposal-assign",
    component: ProposalAssign,
    key: "proposalAssign",
    sidenav: true,
    header: true,
    permission: [Permissions.PROPOSAL_ASSIGN],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/project-stage-status",
    component: ProjectStageStatus,
    key: "projectStageStatus",
    sidenav: true,
    header: true,
    permission: [Permissions.PROJECT_STAGE_STATUS],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/proposal-architect",
    component: ProposalStages,
    key: "ProposalStages",
    sidenav: true,
    header: true,
    permission: [Permissions.PROPOSAL_ARCHITECTURE],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/proposal-architect-am",
    component: ProjectsStageAM,
    key: "ProposalStagesAM",
    sidenav: true,
    header: true,
    permission: [Permissions.PROJECT_STAGE],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.ARCHITECT_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/proposal-ptp",
    component: ProjectStagePtp,
    key: "proposalPTP",
    sidenav: true,
    header: true,
    permission: [Permissions.PROJECT_STAGE],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.AGENCY_ROLE,
      Roles.EMPLOYEE_ROLE,
      Roles.ZONE_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/merchandise-wallet",
    component: MerchandiseWallet,
    key: "merchandiseWallet",
    sidenav: true,
    header: true,
    permission: [Permissions.MERCHANDISE_CATALOGUE],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/set-notification",
    component: SetNotification,
    key: "setNotification",
    sidenav: true,
    header: true,
    permission: [Permissions.SET_NOTIFICATION],
    role: [Roles.SUPER_ADMIN_ROLE, Roles.MARKETING_ROLE],
  },
  {
    exact: true,
    path: "/sales-data-upload",
    component: SalesDataUpload,
    key: "salesDataUpload",
    sidenav: true,
    header: true,
    permission: [Permissions.SALES_DATA_UPLOAD],
    role: [Roles.SUPER_ADMIN_ROLE],
  },
  {
    exact: true,
    path: "/migrate-monthly-data",
    component: MigrateData,
    key: "migrateMonthlyData",
    sidenav: true,
    header: true,
    permission: [Permissions.MIGRATE_MONTHLY_POINTS],
    role: [Roles.SUPER_ADMIN_ROLE],
  },
  {
    path: "/invite-dealer",
    component: SubDealerOrBranch,
    key: "inviteDealer",
    sidenav: true,
    header: true,
    permission: [Permissions.INVITE_BRANCH_SUB_DEALER],
    role: [Roles.DEALER_ROLE],
  },
  {
    path: "/customer-management",
    component: CustomerManagement,
    key: "customerManagement",
    sidenav: true,
    header: true,
    permission: [Permissions.CUSTOMER_LIST],
    role: [
      Roles.DEALER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    path: "/block-list",
    component: BlockCustomer,
    key: "blockList",
    sidenav: true,
    header: true,
    permission: [Permissions.BLOCK_LIST],
    role: [
      Roles.DEALER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  // @NOTE : need to think
  {
    path: "/campaign",
    component: Campaign,
    key: "campaign",
    sidenav: true,
    header: true,
    permission: [Permissions.CAMPAIGN],
    role: [
      Roles.DEALER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    path: "/dashboard",
    component: HeadOfficeDashboard,
    key: "mainDashboard",
    sidenav: true,
    header: true,
    permission: [Permissions.OFFTAKE_DASHBOARD],
    role: [
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.AREA_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/migrate-quarterly-data",
    component: MigrateQuarterlyPoints,
    key: "migrateQuarterlyData",
    sidenav: true,
    header: true,
    permission: [Permissions.MIGRATE_QUARTERLY_POINTS],
    role: [Roles.SUPER_ADMIN_ROLE],
  },
  {
    exact: true,
    path: "/migrate-yearly-data",
    component: MigrateYearlyPoints,
    key: "migrateYearlyData",
    sidenav: true,
    header: true,
    permission: [Permissions.MIGRATE_YEARLY_POINTS],
    role: [Roles.SUPER_ADMIN_ROLE],
  },
  {
    exact: true,
    path: "/customer-intraction",
    component: CustomerIntraction,
    key: "customerIntraction",
    sidenav: true,
    header: true,
    permission: [Permissions.CUSTOMER_INTERACTION],
    role: [Roles.SALES_OFFICER_ROLE, Roles.AREA_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/trade-scheme",
    component: PcrSchemeDealer,
    key: "tradeScheme",
    sidenav: true,
    header: true,
    permission: [Permissions.TRADE_SCHEME],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/trade-scheme-report",
    component: TradeSchemeReport,
    key: "tradeSchemeReport",
    sidenav: true,
    header: true,
    permission: [Permissions.TRADE_SCHEME_REPORT],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.ZTM_ROLE,
      Roles.HEAD_OFFICE_ROLE,
    ],
  },
  {
    exact: true,
    path: "/trade-scheme-dealers",
    component: TradeSchemeDealerReport,
    key: "tradeSchemeDealers",
    sidenav: true,
    header: true,
    permission: [Permissions.TRADE_SCHEME_REPORT],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.ZTM_ROLE,
      Roles.HEAD_OFFICE_ROLE,
    ],
  },
  /**  Redemption Catalog v1  */
  {
    exact: true,
    path: "/redemption-catalog",
    component: GiftCatalog,
    key: "redemptionCatalog",
    sidenav: true,
    header: true,
    permission: [Permissions.REDEMPTION_CATALOGUE],
    role: [
      Roles.DEALER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  /**  Redemption Catalog v2  */
  // {
  //   exact: true,
  //   path: "/redemption-catalog",
  //   component: GiftCatalogV2,
  //   key: "redemptionCatalog",
  //   sidenav: true,
  //   header: true,
  //   permission: [Permissions.REDEMPTION_CATALOGUE],
  //   role: [
  //     Roles.DEALER_ROLE,
  //     Roles.AREA_MANAGER_ROLE,
  //     Roles.REGION_MANAGER_ROLE,
  //     Roles.SALES_OFFICER_ROLE,
  //     Roles.ZONE_MANAGER_ROLE,
  //     Roles.HEAD_OFFICE_ROLE,
  //     Roles.PROJECT_MANAGER_ROLE,
  //     Roles.ZTM_ROLE,
  //   ],
  // },
  {
    path: "/custom-time-picker",
    component: CustomTimePicker,
    exact: true,
    key: "customTimePicker",
    sidenav: true,
    header: true,
    permission: [],
    role: [
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.ZTM_ROLE,
      Roles.HEAD_OFFICE_ROLE,
    ],
  },
  {
    exact: true,
    path: "/product-detail",
    component: GiftItemDetail,
    key: "productDetail",
    sidenav: true,
    header: true,
    permission: [Permissions.REDEMPTION_CATALOGUE],
    role: [
      Roles.DEALER_ROLE,
      Roles.AREA_MANAGER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
      Roles.ZTM_ROLE,
    ],
  },
  {
    exact: true,
    path: "/gift-item-list",
    component: GiftItemList,
    key: "giftItemList",
    sidenav: true,
    header: true,
    permission: [Permissions.GIFT_ITEM],
    role: [Roles.SUPER_ADMIN_ROLE],
  },
  {
    exact: true,
    path: "/exceptional-points-upload",
    component: exceptionalPointsUpload,
    key: "exceptionalPointsUpload",
    sidenav: true,
    header: true,
    permission: [Permissions.EXCEPTIONAL_POINT_UPLOAD],
    role: [Roles.PROJECT_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/dealer-dashboard",
    component: DealerDashboard,
    key: "dealerDashboard",
    sidenav: true,
    header: true,
    permission: [Permissions.DEALER_NEW_DASHBOARD],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/notification-preference",
    component: NotificationPreference,
    key: "notificationPreference",
    sidenav: true,
    header: true,
    permission: [Permissions.NOTIFICATION_PREFERENCE],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/payment/:mode",
    component: paymentSuccess,
    key: "paymentMode",
    sidenav: true,
    header: true,
    permission: [Permissions.BUY_SMS],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/order-history",
    component: OrderHistory,
    key: "OrderHistory",
    sidenav: true,
    header: true,
    permission: [Permissions.ORDER_HISTORY],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/email-preference",
    component: EmailPreference,
    key: "emailPreference",
    sidenav: true,
    header: true,
    permission: [Permissions.EMAIL_PREFERENCE],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/order-history-ho",
    component: OrderHistoryHo,
    key: "OrderHistoryHo",
    sidenav: true,
    header: true,
    permission: [Permissions.ORDER_HISTORY],
    role: [Roles.HEAD_OFFICE_ROLE, Roles.ACCOUNTANT_ROLE],
  },
  {
    exact: true,
    path: "/create-banner",
    component: CreateBanner,
    key: "createBanner",
    sidenav: true,
    header: true,
    permission: [Permissions.CREATE_BANNER],
    role: [Roles.PROJECT_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/banner-list",
    component: BannerList,
    key: "bannerList",
    sidenav: true,
    header: true,
    permission: [Permissions.BANNER_LIST],
    role: [Roles.PROJECT_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/user-updates",
    component: UserUpdates,
    key: "Admin Functionality",
    sidenav: true,
    header: true,
    permission: [Permissions.USER_STATUS_CHANGE],
    role: [Roles.PROJECT_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/brandstore-locator",
    component: BrandStoreLocation,
    key: "BrandStoreLocation",
    sidenav: true,
    header: true,
    permission: [Permissions.MAP_LOCATION],
    role: [Roles.HEAD_OFFICE_ROLE, Roles.PROJECT_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/r/:token",
    component: CustomerReference,
    key: "About",
    sidenav: false,
    header: false,
    isPrivateAndPublic: true,
    permission: [""],
    role: [],
  },
  {
    exact: true,
    path: "/f/:token",
    component: CustomerRating,
    key: "About",
    sidenav: false,
    header: false,
    isPrivateAndPublic: true,
    permission: [""],
    role: [],
  },
  {
    exact: true,
    path: "/b/:token",
    component: GoogleBusiness,
    key: "About",
    sidenav: false,
    header: false,
    isPrivateAndPublic: true,
    permission: [""],
    role: [],
  },
  {
    exact: true,
    path: "/notification-page",
    component: NotificationRedirect,
    key: "Notification",
    sidenav: true,
    header: true,
    isPrivateAndPublic: true,
    permission: [""],
    role: [],
  },
  {
    exact: true,
    path: "/sms-report",
    component: SMSReport,
    key: "smsReport",
    sidenav: true,
    header: true,
    permission: [Permissions.SMS_REPORT],
    role: [Roles.DEALER_ROLE],
  },
  {
    exact: true,
    path: "/poster-list",
    component: PosterList,
    key: "posterList",
    sidenav: true,
    header: true,
    permission: [Permissions.CREATE_POSTER],
    role: [Roles.PROJECT_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/cip-registration",
    component: CipRegitration,
    key: "",
    sidenav: true,
    header: true,
    role: [Roles.SALES_OFFICER_ROLE, Roles.AREA_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/cip-detail",
    component: CipDetail,
    key: "",
    sidenav: true,
    header: true,
    role: [Roles.SALES_OFFICER_ROLE, Roles.AREA_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/cip-summary",
    component: CipSummary,
    key: "",
    sidenav: true,
    header: true,
    role: [Roles.SALES_OFFICER_ROLE, Roles.AREA_MANAGER_ROLE],
  },
  {
    path: "/create-poster",
    component: CreatePoster,
    key: "createPoster",
    sidenav: true,
    header: true,
    permission: [Permissions.CREATE_POSTER],
    role: [Roles.PROJECT_MANAGER_ROLE],
  },
  {
    exact: true,
    path: "/point-report",
    component: PrimaryPointsReportDealer,
    key: "",
    sidenav: true,
    header: true,
    permission: [Permissions.SALES_TREND_REPORT],
    role: [
      Roles.AREA_MANAGER_ROLE,
      Roles.SALES_OFFICER_ROLE,
      Roles.REGION_MANAGER_ROLE,
      Roles.ZONE_MANAGER_ROLE,
      Roles.HEAD_OFFICE_ROLE,
      Roles.PROJECT_MANAGER_ROLE,
    ],
  },
  {
    exact: true,
    path: "/retail-marketing-system",
    component: RetailMarketingSystem,
    key: "",
    sidenav: true,
    header: true,
    permission: [Permissions.RETAIL_BRANDING_LIST],
    role: [Roles.VENDOR_ROLE, Roles.HEAD_OFFICE_ROLE],
  },
  {
    exact: true,
    path: "/retail-marketing-system-list",
    component: RetailMarketingHOSystem,
    key: "",
    sidenav: true,
    header: true,
    permission: [Permissions.RETAIL_BRANDING_LIST],
    role: [Roles.HEAD_OFFICE_ROLE],
  },
  {
    exact: true,
    path: "/retail-marketing-recce",
    component: RetailMarketingRecce,
    key: "",
    sidenav: true,
    header: true,
    permission: [
      Permissions.CREATE_RETAIL_BRANDING,
      Permissions.UPDATE_RETAIL_BRANDING,
    ],
    role: [Roles.VENDOR_ROLE, Roles.HEAD_OFFICE_ROLE],
  },
  {
    exact: true,
    path: "/retail-recce-progress-status",
    component: RecceProgressStatus,
    key: "",
    sidenav: true,
    header: true,
    permission: [
      Permissions.CREATE_RETAIL_BRANDING,
      Permissions.UPDATE_RETAIL_BRANDING,
    ],
    role: [Roles.HEAD_OFFICE_ROLE],
  },
  ,
  // {
  //   exact: true,
  //   path: "/redemption-catalog/v2",
  //   component: GiftCatalogV2,
  //   key: "RedemptionCatalogV2",
  //   sidenav: true,
  //   header: true,
  //   permission: [Permissions.REDEMPTION_CATALOGUE],
  //   // role: [Roles.HEAD_OFFICE_ROLE],
  // },
  {
    exact: true,
    path: "/analysis",
    component: Analysis,
    key: "Analysis",
    sidenav: true,
    header: true,
    permission: [Permissions.JK_ANALYSIS],
    role: [Roles.HEAD_OFFICE_ROLE],
  },
  {
    exact: true,
    path: "/generative_ai",
    component: GenerativeAI,
    key: "GenerativeAI",
    sidenav: true,
    header: true,
    permission: [Permissions.GENERATIVE_AI],
    role: [Roles.HEAD_OFFICE_ROLE],
  },
];

export const onBoardingRouteObj = [
  {
    exact: true,
    path: "/on-boarding",
    component: OnBoardingPage,
    key: "OnBoardingPage",
    sidenav: false,
    header: false,
    permission: [Permissions.ON_BOARDING_PAGE],
    role: [Roles.DEALER_ROLE, Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE],
  },
];
